import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

import { CommonService } from './common.service';
import { MainService } from './main.service';
import { environment } from '../../environments/environment.prod';
import { ApiService } from './api.service';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _httpClient: HttpClient,public _apiService: ApiService, public _translationService :TranslationService, public _commonService: CommonService, private router: Router, private alertController: AlertController, private ngZone: NgZone, private _mainService: MainService) { }

  // Check if allowedDomains includes the current logged in domainname
  isAuthorized(allowedDomains: string[]): boolean {
    if (allowedDomains == null || allowedDomains.length === 0) {
      return true;
    }
    if (!localStorage.authenticationKey) {
      this.router.navigate(['login']);
      this._commonService.isLoggedIn = false;
      return false;
    }
    return allowedDomains.includes(localStorage.domainName.toLowerCase());
  }
  
  changeDefaultPassword(params:any, apiName: any, authKey:any){
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': 'weboffice',
        'X-Security-AuthKey': authKey ? authKey : "",
      })
    };
    return this._httpClient.patch((this._mainService.url ? this._mainService.url : environment.url) + apiName + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), params, httpOptions).toPromise();
  }

  doVerifyPassCode(params: any, apiName: string, authKey:any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': 'weboffice',
        'X-Security-AuthKey': authKey ? authKey : "",
      }),
      observe: "response" as 'body'
    };
    return this._httpClient.post((this._mainService.url ? this._mainService.url : environment.url) + apiName + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), params, httpOptions).pipe(map((res: any) => {
      return res;
    }));
  }

doLogin(params: any, apiName: string): Observable < any > {
  let httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      'X-LoginSource': 'weboffice'
    }),
    observe: "response" as 'body'
  };
  return this._httpClient.post((this._mainService.url ? this._mainService.url : environment.url) + apiName + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), params, httpOptions).pipe(map((res: any) => {
    return res;
  }));
}

  doDomainLogin(params: any, apiName: string, authKey: string): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': 'weboffice',
        'X-Security-AuthKey': authKey ? authKey : "",
      })
    };
    return this._httpClient.post((this._mainService.url ? this._mainService.url : environment.url) + apiName + "?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), params, httpOptions).pipe(map((res: any) => {
      if (res) {
        localStorage.setItem('authenticationKey', res.AuthenticationKey);
      }
      return res;
    }));
  }

  async logout() {
    const alert = await this.alertController.create({
      header: this._commonService.getLocalTranslation('label-logOut'),
      message: this._commonService.getLocalTranslation('label-closeAndLogout'),
      buttons: [
        {
          text: this._commonService.getLocalTranslation('label-ok'),
          handler: () => {

            //  logout the user
            this._commonService.presentLoading();
            this.doLogout(localStorage.authenticationKey).subscribe(
              (res) => {
                this._commonService.dismiss();
               
                this._commonService.redirectURL = null;
                 this._commonService.showDashboardTiles = true;
                 this._commonService.isAdmin = false;
                 this._commonService.isEmployee = false;
                 this._commonService.isCustomer = false;
                 this._commonService.isDriver = false;
                this._commonService.userRoles = [];
                this._commonService.clearData();   
                this._commonService.isLoggedIn = false
                this.ngZone.run(() => this.router.navigate(['/login'], { replaceUrl: true }));
             
              },
              (err) => {
                this._commonService.dismiss();
              
                this._commonService.redirectURL = null;
                this._commonService.showDashboardTiles = true;
                this._commonService.isAdmin = false;
                this._commonService.isAdmin = false;
                this._commonService.isEmployee = false;
                this._commonService.isCustomer = false;
                this._commonService.isDriver = false;
                this._commonService.userRoles = []
                this._commonService.clearData();
                this._commonService.isLoggedIn = false
                this.ngZone.run(() => this.router.navigate(['/login'], { replaceUrl: true }));
              }
            )
          }
        },
        {
          text: this._commonService.getLocalTranslation('label-cancel'),
          role: 'cancel',
          handler: () => {
          }
        }
      

      ]
    });
    await alert.present();
  }

  doLogout(authKey): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey ? authKey : "",
      'X-LoginSource': 'weboffice'
    })
    return this._httpClient.put((this._mainService.url ? this._mainService.url : environment.url) + 'Logout?localeCode=' + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), {}, { headers: headers }).pipe(map((res: any) => {
      return res;
    }));
  }


  loginOneTimeLink(id): Promise<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-LoginSource': 'weboffice'
      }),
      observe: "response" as 'body'
    };
    return this._httpClient.post((this._mainService.url ? this._mainService.url : environment.url) + "EmaillinkAuthentication/" + id + "/Login?localeCode=" + ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043),'', httpOptions).toPromise();
  }



  async setLoginData(res){
    let currentTime = new Date();
    localStorage.sessionStartTime = currentTime.getHours();

    localStorage.setItem('userId', res.body.UserId);
    localStorage.setItem('userName', res.body.UserName);
    localStorage.setItem('firstName', res.body.FirstName);
    localStorage.setItem('domainLocales', JSON.stringify(res.body.Locales));
    localStorage.setItem('menuItems', JSON.stringify(res.body.MenuItems));
    localStorage.setItem('domainName', res.body.DomainName);
    localStorage.setItem('authenticationKey', res.body.AuthenticationKey);
    localStorage.setItem('domainId', res.body.DomainId);
    localStorage.setItem('roleCode', res.body.RoleCode);
    localStorage.setItem('Datecreated', res.body.Datecreated ? res.body.Datecreated : '');
    localStorage.setItem('FirstLogin', res.body.FirstLogin ? res.body.FirstLogin : 'false');
    localStorage.setItem('customerId', res.body.CustomerId ? res.body.CustomerId : '');
    localStorage.setItem('customerName', res.body.CustomerName ? res.body.CustomerName : '');
    localStorage.setItem('preferredLocationId', res.body.PreferredLocationId ? res.body.PreferredLocationId : '');
    localStorage.setItem('preferredVehicleId', res.body.PreferredVehicleId ? res.body.PreferredVehicleId : '');
    localStorage.setItem('knowledgeTestStatus', res.body.KnowledgeTestStatus ? res.body.KnowledgeTestStatus : '');

    localStorage.setItem('locale', res.body.DefaultLocale);
    this._commonService.locale = res.body.DefaultLocale;
    await this._translationService.getTranslation(res.body.DefaultLocale);

    this._commonService.phoneNumber = res.body.DomainSubscriptionContactNumber ? res.body.DomainSubscriptionContactNumber : ''

    // if (res.body.Logo) {
    //   this._commonService.logo = 'data:image/png;base64,' + res.body.Logo;
    //   localStorage.setItem('Logo', 'data:image/png;base64,' + res.body.Logo);
    // } else {
    //   this._commonService.logo = null;
    //   localStorage.removeItem('Logo');
    // }

    if (res.body.CustomerLogo) {
      this._commonService.customerLogo = 'data:image/png;base64,' + res.body.CustomerLogo;
      localStorage.setItem('customerLogo', 'data:image/png;base64,' + res.body.CustomerLogo);
    } else {
      this._commonService.customerLogo = null;
      localStorage.removeItem('customerLogo');
    }

    this._commonService.userRoles = localStorage.roleCode?localStorage.roleCode.split(','):[]; 
    
    if (this._commonService.userRoles.includes('UserRole_Administrator')) {
      this._commonService.isAdmin = true;
      this._commonService.showDashboardTiles = false;
    }
    if (this._commonService.userRoles.includes('UserRole_Employee')) {
      this._commonService.isEmployee = true;
    }
    if (this._commonService.userRoles.includes('UserRole_Customer')) {
      this._commonService.isCustomer = true;
    }
    if (this._commonService.userRoles.includes('UserRole_Driver')) {
      this._commonService.isDriver = true;
    }
    if (this._commonService.userRoles.includes('UserRole_Invoicer')) {
      this._commonService.isInvoicer = true
    }


    localStorage.setItem('userRoles', res.body.UserRoles ? JSON.stringify(res.body.UserRoles) : null);


    this._commonService.domainLocales = res.body.Locales;

    this._commonService.menuItems = res.body.MenuItems;
    this._commonService.domainName = res.body.DomainName;

    this._commonService.domain = {
      DomainId: res.body.DomainId,
      Name: res.body.DomainName
    }

    if (localStorage.currentImage) {
      localStorage.removeItem('currentImage')
    }

    this._commonService.redirectURL = null;

    const externallink = localStorage.externallink

    if(externallink){
      if(externallink === 'invoicelines'){
        this.router.navigate(['/invoicing/invoicelines'], { replaceUrl: true });
      }else if(externallink === 'invoicesdetail'){
        const invoiceId = localStorage.getItem('invoiceId');
        this.router.navigate([`/invoicing/invoices/${invoiceId}`], { replaceUrl: true });
      }
      else if(externallink === 'invoices'){
        this.router.navigate([`/invoicing/invoices`], { replaceUrl: true });
      }
    }else if(this._commonService.userRoles.includes("UserRole_Driver") && localStorage.knowledgeTestStatus !== 'Passed'){
      this.router.navigate(['/knowledgetest'], { replaceUrl: true });
    }else if(this._commonService.userRoles.length === 1 && this._commonService.userRoles.includes('UserRole_Invoicer')){
      this.router.navigate(['/invoicing/invoices'], { replaceUrl: true });
    }else{
      this.router.navigate(['/reservations'], { replaceUrl: true });
    }

    this._commonService.isLoggedIn = true;


    localStorage.domains = res.body.LinkedDomains ? JSON.stringify(res.body.LinkedDomains) : null;
    this._commonService.dismiss();


    try {
      let settingList = await this._apiService.getSettings('', '', '', 1, 0);
      settingList.body.forEach((value, index) => {
        if (value.SettingName == "Force2FactorAuthentication") {
          localStorage.Force2FactorAuthentication = value.SettingValue;
        }

      });

      let response = await this._apiService.getFiles('', '', '', 1, 0);
      let fileList = response.body;

      localStorage.fileList = fileList ? JSON.stringify(fileList): [];



    } catch (error) {
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR);
    }

  }


  

}
